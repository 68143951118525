import { createReducer, on } from '@ngrx/store';
import * as NotificationActions from './notifications.actions';

export interface Notification {
  loading: boolean;
  loaded: boolean;
  entries: any[];
}

export const notificationFeatureKey = 'notifications';
export const initialState: Notification = {
  loading: false,
  loaded: false,
  entries: []
};

export const notificationReducer = createReducer(
  initialState,

  on(NotificationActions.getAccountNotifications, state => ({
    ...state,
    loading: true
  })),

  on(NotificationActions.getAccountNotificationsSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    entries: action.data
  })),

  on(NotificationActions.getAccountNotificationsFailure, state => ({
    ...state,
    loading: false,
    loaded: true
  }))
);
